// Event Bus
import {EventBus} from '@/main'

// SweetAlert
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
// Plugins
import i18n from '@/plugins/i18n'

// Services
import StudentDocumentService from '@/services/StudentDocumentService'

// Helpers
import showErrors from '@/helpers/showErrors'

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ffffff',
    icon: 'question'
}
Vue.use(VueSweetalert2, options);

export default function(user,documentType, callbackConfig) {
    let document;
    let config = {};
    if(callbackConfig != null){
        config = {
            config: callbackConfig
        }
    }

    switch(documentType) {
        case 'transcript':
            document = {
                callback: (lang) => {
                    StudentDocumentService.downloadTranscript(user.student_program_id, lang)
                                          .then(response => {
                                              let fileName = user.student_number + '-' + i18n.t('file_name_transcript') + '-' + lang + '.pdf'
                                              EventBus.$emit('pdfViewerModalShow', {
                                                  pdfFileData: response.data,
                                                  pdfFileName: fileName,
                                                  ...config
                                              });
                                          })
                                          .catch(e => {
                                              showErrors(e, null, true)
                                          })
                }
            }
            break;

        case 'transcript_in_sealed_envelope':
            document = {
                callback: (lang) => {
                    StudentDocumentService.downloadTranscript(user.student_program_id, lang)
                                          .then(response => {
                                              let fileName = user.student_number + '-' + i18n.t('file_name_transcript') + '-' + lang + '.pdf'
                                              EventBus.$emit('pdfViewerModalShow', {
                                                  pdfFileData: response.data,
                                                  pdfFileName: fileName,
                                                  ...config
                                                  });
                                          })
                                          .catch(e => {
                                              showErrors(e, null, true)
                                          })
                }
            }
            break;

        case 'military_status':
            if (['OL', 'L'].includes(user.program_level)) {
                document = {
                    callback: (lang) => {
                        StudentDocumentService.downloadMilitaryStatus(user.student_program_id, lang)
                                              .then(response => {
                                                  let fileName = user.student_number + '-' + i18n.t('file_name_military_status') + '-' + lang + '.pdf'
                                                  EventBus.$emit('pdfViewerModalShow', {
                                                      pdfFileData: response.data,
                                                      pdfFileName: fileName,
                                                      ...config
                                                  });
                                              })
                                              .catch(e => {
                                                  showErrors(e, null, true)
                                              })
                    }
                }
            }
            break;

        case 'military_tecil':
            //Askerlik Tecil belgesi yok

            break;

        case 'success_ranking':
            //Başarı Sıralaması yok

            break;

        case 'language_proficiency_certificate':
            document = {
                callback: (lang) => {
                    EventBus.$emit('closeDocumentModal', true);
                    Vue.swal
                       .fire({
                           input: 'textarea',
                           inputLabel: i18n.t('note_you_want_to_add'),
                           inputValue: '',
                           inputPlaceholder: i18n.t('note'),
                           inputAttributes: {
                               'aria-label': i18n.t('note')
                           },
                           showCancelButton: true
                       }).then((result) => {
                        if (result.isConfirmed) {
                            StudentDocumentService.downloadLanguageProficiency(user.student_program_id, lang, result.value)
                                                  .then(response => {
                                                      let fileName = user.student_number + '-' + i18n.t('file_name_language_proficiency') + '-' + lang + '.pdf'
                                                      EventBus.$emit('pdfViewerModalShow', {
                                                          pdfFileData: response.data,
                                                          pdfFileName: fileName,
                                                          ...config
                                                      });
                                                  })
                                                  .catch(e => {
                                                      showErrors(e, null, true)
                                                  })
                        }
                    })

                }
            }
            break;

        case 'disciplinary_record':
            document = {
                callback: (lang) => {
                    EventBus.$emit('closeDocumentModal', true);
                    Vue.swal
                        .fire({
                            input: 'textarea',
                            inputLabel: i18n.t('note_you_want_to_add'),
                            inputValue: '',
                            inputPlaceholder: i18n.t('note'),
                            inputAttributes: {
                                'aria-label': i18n.t('note')
                            },
                            showCancelButton: true
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                let data= {
                                    language:lang
                                }
                                StudentDocumentService.downloadDisciplineRegistry(user.id, data)
                                    .then(response => {
                                        let fileName = user.student_number + '-' + i18n.t('discipline_registry') + '-' + lang + '.pdf';
                                        EventBus.$emit('pdfViewerModalShow', {
                                            pdfFileData: response.data,
                                            pdfFileName: fileName,
                                            ...config
                                        });
                                    })
                                    .catch(e => {
                                        showErrors(e, null, true)
                                    })
                            }
                        })
                }
            }

            break;

        case 'prep_transcript':
            document = {
                callback: (lang) => {
                    StudentDocumentService.downloadPrepTranscript(user.student_program_id, lang)
                                          .then(response => {
                                              let fileName = user.student_number + '-' + i18n.t('file_name_prep_transcript') + '-' + lang + '.pdf'
                                              EventBus.$emit('pdfViewerModalShow', {
                                                  pdfFileData: response.data,
                                                  pdfFileName: fileName,
                                                  ...config
                                              });
                                          })
                                          .catch(e => {
                                              showErrors(e, null, true)
                                          })
                }
            }
            break;

        case 'student_document':
            document = {
                callback: (lang) => {
                    EventBus.$emit('closeDocumentModal', true);
                    Vue.swal
                       .fire({
                           input: 'textarea',
                           inputLabel: i18n.t('note_you_want_to_add'),
                           inputValue: '',
                           inputPlaceholder: i18n.t('note'),
                           inputAttributes: {
                               'aria-label': i18n.t('note')
                           },
                           showCancelButton: true
                       })
                       .then((result) => {
                           if (result.isConfirmed) {
                               StudentDocumentService.downloadStudentCertificate(user.student_program_id, lang, result.value)
                                                     .then(response => {
                                                         let fileName = user.student_number + '-' + i18n.t('file_name_student_certificate') + '-' + lang + '.pdf';
                                                         EventBus.$emit('pdfViewerModalShow', {
                                                             pdfFileData: response.data,
                                                             pdfFileName: fileName,
                                                             ...config
                                                         });
                                                     })
                                                     .catch(e => {
                                                         showErrors(e, null, true)
                                                     })
                           }
                       })
                }
            }

            break;

        case 'full_transcript':
            //Disiplin Sicil Belgesi yok

            break;

        case 'undergraduate_transfer_no_barrier':
            //Disiplin Sicil Belgesi yok

            break;

        case 'diploma':
            document = {
                callback: () => {
                    StudentDocumentService.downloadDiploma(user.id).then(response => {
                        EventBus.$emit('htmlViewerModalShow', response.data);
                                          })
                                          .catch(e => {
                                              showErrors(e, null, true)
                                          })
                }
            }

            break;

        default:
            document = false
    }


    return document;
}


